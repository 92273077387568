<div>
  <!-- Menu Button -->
  <button class="md:hidden fixed top-6 left-4 z-50 text-tertiary" (click)="isNavVisible = !isNavVisible">
    <i class="pi pi-bars text-3xl"></i>
  </button>

  <!-- Overlay for closing menu on mobile -->
  <div *ngIf="isNavVisible" class="md:hidden fixed inset-0 z-40 bg-black/50" (click)="isNavVisible = false"></div>

  <!-- Sidebar Menu -->
  <nav
    [ngClass]="{ 'translate-x-0': isNavVisible, '-translate-x-full': !isNavVisible }"
    class="z-50 h-screen w-[106px] bg-no-repeat bg-cover fixed flex flex-col items-center pt-6 gap-2 transition-transform transform md:translate-x-0 overflow-y-auto"
  >
    <img class="w-16" src="/assets/images/logo-white.png" />

    <div
      class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
      [routerLink]="['/dashboard']"
      [ngClass]="rla.isActive ? 'bg-white/20' : ''"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive
      #rla="routerLinkActive"
      (click)="closeNavOnMobile()"
    >
      <i class="pi pi-home text-2xl"></i>
      <span class="text-xs">Inicio</span>
    </div>

    <div
      *ngIf="isManager$ | async"
      class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
      [routerLink]="['/dashboard/club/teams']"
      [ngClass]="myTeams.isActive ? 'bg-white/20' : ''"
      routerLinkActive
      #myTeams="routerLinkActive"
      (click)="closeNavOnMobile()"
    >
      <i class="pi pi-users text-2xl"></i>
      <span class="text-xs">Mis Equipos</span>
    </div>
    <div
      *ngIf="isManager$ | async"
      class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
      [routerLink]="['/dashboard/club/players']"
      [ngClass]="myPlayers.isActive ? 'bg-white/20' : ''"
      routerLinkActive
      #myPlayers="routerLinkActive"
      (click)="closeNavOnMobile()"
    >
      <i class="pi pi-user text-2xl"></i>
      <span class="text-xs">Mis Jugadores</span>
    </div>
    <div
      *ngIf="isRefereeCoordinator$ | async"
      class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
      [routerLink]="['/dashboard/referees/coordinate']"
      [ngClass]="referees.isActive ? 'bg-white/20' : ''"
      routerLinkActive
      #referees="routerLinkActive"
      (click)="closeNavOnMobile()"
    >
      <i class="pi pi-user text-2xl"></i>
      <span class="text-xs">Asignar arbitros</span>
    </div>

    @if (isAdmin$ | async) {
      <div
        class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
        [routerLink]="['/dashboard/users']"
        [ngClass]="users.isActive ? 'bg-white/20' : ''"
        routerLinkActive
        #users="routerLinkActive"
        (click)="closeNavOnMobile()"
      >
        <i class="pi pi-user text-2xl"></i>
        <span class="text-xs">Usuarios</span>
      </div>

      <div
        class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
        [routerLink]="['/dashboard/clubs']"
        [ngClass]="clubs.isActive ? 'bg-white/20' : ''"
        routerLinkActive
        #clubs="routerLinkActive"
        (click)="closeNavOnMobile()"
      >
        <i class="pi pi-building text-2xl"></i>
        <span class="text-xs">Clubs</span>
      </div>

      <div
        class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
        [routerLink]="['/dashboard/teams']"
        [ngClass]="teams.isActive ? 'bg-white/20' : ''"
        routerLinkActive
        #teams="routerLinkActive"
        (click)="closeNavOnMobile()"
      >
        <i class="pi pi-users text-2xl"></i>
        <span class="text-xs">Equipos</span>
      </div>

      <div
        class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
        [routerLink]="['/dashboard/competitions']"
        [ngClass]="competition.isActive ? 'bg-white/20' : ''"
        routerLinkActive
        #competition="routerLinkActive"
        (click)="closeNavOnMobile()"
      >
        <i class="pi pi-sitemap text-2xl"></i>
        <span class="text-xs">Competiciones</span>
      </div>

      <div
        class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
        [routerLink]="['/dashboard/calendar']"
        [ngClass]="calendar.isActive ? 'bg-white/20' : ''"
        routerLinkActive
        #calendar="routerLinkActive"
        (click)="closeNavOnMobile()"
      >
        <i class="pi pi-calendar text-2xl"></i>
        <span class="text-xs">Calendario</span>
      </div>

      <div
        class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
        [routerLink]="['/dashboard/change-dates']"
        [ngClass]="changeDates.isActive ? 'bg-white/20' : ''"
        routerLinkActive
        #changeDates="routerLinkActive"
        (click)="closeNavOnMobile()"
      >
        <i class="pi pi-calendar-clock text-2xl"></i>
        <span class="text-xs">Cambios Fechas</span>
      </div>
      <div
        class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
        [routerLink]="['/dashboard/incidents']"
        [ngClass]="incidents.isActive ? 'bg-white/20' : ''"
        routerLinkActive
        #incidents="routerLinkActive"
        (click)="closeNavOnMobile()"
      >
        <i class="pi pi-volume-up text-2xl"></i>
        <span class="text-xs">Incidencias</span>
      </div>
      <div
        class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
        [routerLink]="['/dashboard/transfers']"
        [ngClass]="transfers.isActive ? 'bg-white/20' : ''"
        routerLinkActive
        #transfers="routerLinkActive"
        (click)="closeNavOnMobile()"
      >
        <i class="pi pi-arrow-right-arrow-left text-2xl"></i>
        <span class="text-xs">Traspasos</span>
      </div>

      <div
        class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
        [routerLink]="['/dashboard/movements']"
        [ngClass]="movements.isActive ? 'bg-white/20' : ''"
        routerLinkActive
        #movements="routerLinkActive"
        (click)="closeNavOnMobile()"
      >
        <i class="pi pi-euro text-2xl"></i>
        <span class="text-xs">Ingresos</span>
      </div>

      <div
        class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
        [routerLink]="['/dashboard/license-rates']"
        [ngClass]="licenses.isActive ? 'bg-white/20' : ''"
        routerLinkActive
        #licenses="routerLinkActive"
        (click)="closeNavOnMobile()"
      >
        <i class="pi pi-star text-2xl"></i>
        <span class="text-xs">Tarifas</span>
      </div>
      <div
        class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
        [routerLink]="['/dashboard/emails']"
        [ngClass]="emails.isActive ? 'bg-white/20' : ''"
        routerLinkActive
        #emails="routerLinkActive"
        (click)="closeNavOnMobile()"
      >
        <i class="pi pi-envelope text-2xl"></i>
        <span class="text-xs">Emails</span>
      </div>
    }

    <div
      class="flex flex-col items-center text-white w-full gap-2 py-2 cursor-pointer hover:bg-white/20"
      (click)="logout(); closeNavOnMobile()"
    >
      <i class="text-2xl pi pi-sign-out"></i>
      <span class="text-xs">Salir</span>
    </div>
  </nav>

  <div class="w-auto pt-4 px-6 pb-8 md:ml-[70px] lg:ml-[106px]" [class.md:ml-0]="!isNavVisible">
    <router-outlet></router-outlet>
  </div>

  <p-toast></p-toast>
</div>
