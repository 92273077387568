import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { COLLECTIONS } from '@models/collections';
import { Movement } from '@models/inscription';
import { LicenseUserRate } from '@models/license';
import { Team } from '@models/teams';
import { combineLatest, map, shareReplay, switchMap } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { SeasonManagerService } from './season-manager.service';
import { PaymentType } from '@models/masters';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  private readonly expenses$ = this.firestore
    .listDocuments<Movement>(COLLECTIONS.MOVEMENTS, where('movementType', '==', 'EXPENDITURE'))
    .pipe(shareReplay(1));

  private readonly incomes$ = this.firestore
    .listDocuments<Movement>(COLLECTIONS.MOVEMENTS, where('movementType', '==', 'INCOME'))
    .pipe(shareReplay(1));

  private readonly teams$ = this.seasonManager.season$.pipe(
    switchMap((season) => this.firestore.listDocuments<Team>(COLLECTIONS.TEAMS, where('seasonYear', '==', season))),
    shareReplay(1),
  );

  private readonly licenses$ = this.seasonManager.season$.pipe(
    switchMap((seasonYear) =>
      this.firestore.listDocumentsGroup<LicenseUserRate>(
        COLLECTIONS.USERS_LICENSES,
        where('seasonYear', '==', seasonYear),
      ),
    ),
    shareReplay(1),
  );

  public adminStats$ = combineLatest([this.expenses$, this.incomes$, this.teams$, this.licenses$]).pipe(
    map(([expenses, incomes, teams, licenses]) => {
      const totalExpenses = expenses.reduce((acc, curr) => acc + +curr.amount, 0);
      const totalIncomes = incomes.reduce((acc, curr) => acc + +curr.amount, 0);
      const incomesByType = incomes.reduce(
        (acc, income) => {
          const type = income.paymentType as PaymentType;
          if (type && typeof type === 'string') {
            if (!acc[type]) {
              acc[type] = 0;
            }
            acc[type] += +income.amount;
          }
          return acc;
        },
        {} as Record<PaymentType, number>,
      );

      const licensesByUserAndType = licenses.reduce((acc, license) => {
        const userId = license.userId;
        const licenseType = license.licenseType;

        if (!userId || !licenseType || typeof userId !== 'string' || typeof licenseType !== 'string') {
          return acc;
        }

        if (!acc.has(userId)) {
          acc.set(userId, new Set<string>());
        }

        const userLicenses = acc.get(userId);
        if (userLicenses) {
          userLicenses.add(licenseType);
        }

        return acc;
      }, new Map<string, Set<string>>());

      const licensesByType = Array.from(licensesByUserAndType.values()).reduce(
        (acc, licenseTypesSet) => {
          licenseTypesSet.forEach((type) => {
            if (!acc[type]) {
              acc[type] = 0;
            }
            acc[type]++;
          });
          return acc;
        },
        {} as Record<string, number>,
      );

      const uniqueUsersWithLicenses = licensesByUserAndType.size;

      return {
        totalExpenses,
        totalIncomes,
        netBalance: totalExpenses - totalIncomes,
        teamsCount: teams.length,
        uniqueUsersWithLicenses,
        licensesByType,
        incomesByType,
      };
    }),
  );

  constructor(
    private firestore: FirestoreService,
    private seasonManager: SeasonManagerService,
  ) {}
}
